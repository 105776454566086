import React, { FC, memo } from 'react';

interface Props {
	showText?: boolean;
	showTextAudit?: boolean;
}

const Header: FC<Props> = memo(({ showText, showTextAudit }) => (
	<>
		{!showText && !showTextAudit && <div className="b-headline u-text-center u-bg-color-blue"></div>}
		{showText && (
			<section className="b-headline u-text-center u-bg-color-blue">
				<div className="row-main">
					<h1 className="b-headline__title u-color-white">
						Je váš web <span className="u-color-blue-light">dost rychlý?</span>
					</h1>
					<p className="b-headline__annot u-color-white">
						Otestujte si web a&nbsp;sledujte vývoj jeho rychlosti v&nbsp;čase
					</p>
				</div>
			</section>
		)}
		{showTextAudit && (
			<section className="b-headline u-text-center u-bg-color-blue">
				<div className="row-main">
					<h1 className="b-headline__title u-color-white">
						Víme, jak váš web <span className="u-color-blue-light">zrychlit!</span>
					</h1>
					<p className="b-headline__annot u-color-white">
						Objednejte si náš audit a&nbsp;předběhněte konkurenci
					</p>
					<p className="b-headline__btn">
						<a href="#audit" className="btn">
							<span className="btn__text">Objednat audit</span>
						</a>
					</p>
				</div>
			</section>
		)}
	</>
));

export default Header;
